// 机构模快

import LayoutMain from '@/layout/LayoutMain'

const mechanism = {
    path: '/mechanism',
    component: LayoutMain,
    redirect: '/mechanism/mechanismManage',
    meta: {
        title: '学校管理 ',
        LoginRoad: '学校管理 ',
        icon: 'el-icon-s-tools'
    },
    children: [
        {
            path: '/mechanism/mechanismManage',
            name: 'mechanismManage',
            meta: {
                title: '区县管理',
                LoginRoad: '区县管理',
            },
            component: () => import('@/views/mechanism/mechanismManage/mechanismManage.vue'),
        },
        {
            path: '/mechanism/areaManage',
            name: 'areaManage',
            meta: {
                title: '学校管理',
                LoginRoad: '学校管理',
            },
            component: () => import('@/views/mechanism/schoolManage/schoolManage.vue'),
        },
        {
            path: '/mechanism/classEditing',
            name: 'classEditing',
            meta: {
                title: '班级管理',
                LoginRoad: '班级管理',
                hideNav: true
            },
            component: () => import('@/views/mechanism/schoolManage/classEditing.vue'),
        },
        {
            path: '/mechanism/teachPlaceManage',
            name: 'teachPlaceManage',
            meta: {
                title: '教学点管理',
                LoginRoad: '教学点管理',
                hideNav: true
            },
            component: () => import('@/views/mechanism/teachPlaceManage/teachPlaceManage.vue'),
        },
        {
            path: '/mechanism/examPlaceManage',
            name: 'examPlaceManage',
            meta: {
                title: '实验室管理',
                LoginRoad: '实验室管理',
                hideNav: true
            },
            component: () => import('@/views/mechanism/examPlaceManage/examPlaceManage.vue'),
        },
        {
            path: '/mechanism/editExamPlaceManage',
            name: 'editExamPlaceManage',
            meta: {
                title: '实验室编辑',
                LoginRoad: '实验室编辑',
                hideNav: true
            },
            component: () => import('@/views/mechanism/examPlaceManage/editExamPlaceManage.vue'),
        },
        // {
        //     path: '/mechanism/placeRoomManage',
        //     name: 'placeRoomManage',
        //     meta: {
        //         title: '功能室管理旧',
        //         LoginRoad: '功能室管理旧',
        //     },
        //     component: () => import('@/views/mechanism/placeRoomManage/placeRoomManage.vue'),
        // },
        {
            path: '/mechanism/schoolRoomManage',
            name: 'schoolRoomManage',
            meta: {
                title: '功能室管理',
                LoginRoad: '功能室管理',
            },
            component: () => import('@/views/mechanism/schoolRoomManage/schoolRoomManage.vue'),
        }
    ]
}

export default mechanism