
const msgType = {
    // 默认消息
    defaultMsg: '100001',
    // 心跳
    heartMsg: '100112',
    // 通知发送心跳
    heartInitMsg: '100113',
    // 认证
    authMsg: '100222'
}


function geneMsg()  {
    return {
        magicNum: 'rocpow',
        versionNum: '2.1',
        topic: 'default',
        from: '1',
        to: '1',
    }
}


export { geneMsg, msgType }