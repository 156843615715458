const localStorage = window.localStorage || localStorage
const tokenKey = 'RPAuthorization'

export function getToken () {
  return localStorage.getItem(tokenKey)
}
export function setToken (token) {
  return localStorage.setItem(tokenKey, token)
}
export function removeToken () {
  return localStorage.removeItem(tokenKey)
}

export function getStore (key) {
  return localStorage.getItem(key)
}
export function setStore (key, value) {
  return localStorage.setItem(key, value)
}
export function removeStore (key) {
  return localStorage.removeItem(key)
}

const temporaryName = 'temporaryName'
export function getName () {
  return localStorage.getItem(temporaryName)
}
export function setName (value) {
  return localStorage.setItem(temporaryName, value)
}
export function removeName (key) {
  return localStorage.removeItem(temporaryName)
}
const loginRoad = 'loginRoad'
export function getLoginRoad () {
  return localStorage.getItem(loginRoad)
}
export function setLoginRoad (value = true) {
  return localStorage.setItem(loginRoad, value)
}
export function removeLoginRoad (key) {
  return localStorage.removeItem(loginRoad)
}
