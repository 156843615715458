<template>
  <el-container>
    <el-aside width="auto" :style="loginCity?'background-color:#00162B':''">
      <el-scrollbar>
        <LayoutAside></LayoutAside>
      </el-scrollbar>
    </el-aside>
    <el-container>
      <el-header v-show="topShow">
        <LayoutHeader></LayoutHeader>
      </el-header>
      <el-main :style="topShow
      ?'padding:12px 20px 0 20px'
      :'height:100vh;padding:0px'">
        <!-- <tags-view></tags-view> -->
        <keep-alive>
          <router-view v-if="cache" class="router-view"></router-view>
        </keep-alive>
        <router-view v-if="!cache" class="router-view"></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { getLoginRoad } from "@/utils/token.js";
import LayoutHeader from "./LayoutHeader.vue";
import LayoutAside from "./LayoutAside.vue";
import tagsView from "./components/tagsView.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "LayoutMain",
  data () {
    return {
      loginCity: false,
      topShow: true,
      cache: false
    }
  },
  components: { LayoutHeader, LayoutAside, tagsView },
  computed: {
    ...mapGetters(["isCollapse"]),
  },
  created () {
    this.loginCity = !!getLoginRoad()
    this.cssStyleFunc()
    this.cache = this.$route.meta.cacheKeep
  },
  watch: {
    $route (e) {
      this.cssStyleFunc()
      // console.log('e.meta.cacheKeep', !!e.meta.cacheKeep);
      this.cache = !!e.meta.cacheKeep
    },
  },
  methods: {
    cssStyleFunc () {
      // console.log('改了路由');
      if (this.$route.name == 'home') {
        this.topShow = false
      } else {
        this.topShow = true
      }
    }
  }
};
</script>

<style lang="scss">
.el-aside {
  background-color: rgb(48, 65, 86);
  color: #333;
  text-align: center;
  height: 100vh;
}
.el-scrollbar {
  height: 100%;
}
.el-scrollbar__wrap {
  // width: 110%;
  // height: 120%;
  overflow-x: hidden !important;
  overflow-y: auto;
}

.el-header {
  background-color: #fff;
  color: #333;
  text-align: center;
  line-height: 56px;
  height: 56px !important;
  box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
  margin-bottom: 3px;
}
.el-container > .el-container {
  height: 100vh;
}
.el-main {
  // background-color: #dfe6ec;
  color: #333;
  height: calc(100vh - 56px);
  // padding: 0 !important;
  position: relative;
  // padding-top: 46px !important;
  // padding-top: 12px !important;
  // padding-bottom: 0 !important;
  .router-view {
    height: 100%;
    // padding: 20px;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
</style>
