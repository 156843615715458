import Vue from 'vue'
import App from './App.vue'
// vue路由
import router from './router'
// vuex状态管理
import store from './store'
// import axios from 'axios';
// 饿了么UI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// 去除默认样式
import 'normalize-css'
// 公共引用的样式
import './style/index.scss'

import qs from 'qs'
// 引入echarts
import * as echarts from 'echarts'
import 'echarts-liquidfill'
//中文汉字转拼音
import pinyin from 'js-pinyin'
//设置全局变量
import Global from './views/Global.vue'
//特殊字符自动替空
import {validForbid, verifyInput} from './utils/util'
import {apiAddress, imageAddress, mediaAddress, mediaHttpAddress, wsAddress} from './utils/publicFunc'
//全局组件注册
import uploadExcelCom from "./components/uploadExcelCom";
import pictureLayer from "./components/pictureLayer";
/**
 * 自定义指令
 */
// 按钮节流
import throttling from "./utils/throttle";
// 正则修改input
import changeVal from "./utils/changeVal";
import {getToken} from "@/utils/token";
import {connectServer} from '@/utils/webSocket'

Vue.prototype.$qs = qs

Vue.prototype.$echarts = echarts
Vue.prototype.$pinyin = pinyin
Vue.prototype.$Global = Global

Vue.prototype.$validForbid = validForbid
Vue.prototype.$verifyInput = verifyInput

Vue.prototype.$imageAddress = imageAddress

Vue.prototype.$apiAddress = apiAddress

Vue.prototype.$wsAddress = wsAddress

Vue.prototype.$mediaAddress = mediaAddress

Vue.prototype.$mediaHttpAddress = mediaHttpAddress
// 设置本地缓存24小时的有效期
const expire = 1000 * 60 * 60 * 24;
Vue.prototype.$expire = expire;
// 全局日期时间过滤器
// use {{时间戳|dateFormat}}
Vue.filter('dateFormat', function (v) {
  let date = new Date(v)
  if (Number.isNaN(Number(date))) {
    date = new Date()
  }
  let y = date.getFullYear()
  let mon = (date.getMonth() + 1 + '').padStart(2, '0')
  let d = (date.getDate() + '').padStart(2, '0')
  let h = (date.getHours() + '').padStart(2, '0')
  let min = (date.getMinutes() + '').padStart(2, '0')
  let s = (date.getSeconds() + '').padStart(2, '0')
  return [y, mon, d].join('-') + ' ' + [h, min, s].join(':')
})

Vue.prototype.msgSuccess = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: 'success',
    customClass: 'customClassNotify',
  })
}

Vue.prototype.msgWarning = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: 'warning',
    customClass: 'customClassNotify',
  })
}

Vue.prototype.msgError = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: 'error',
    customClass: 'customClassNotify',
  })
}

Vue.prototype.msgInfo = function (msg) {
  this.$message.info(msg)
}

Vue.prototype.notifySuccess = function (msg) {
  this.$notify({
    title: '成功',
    message: msg,
    type: 'success',
    customClass: 'customClassNotify',
  })
}

Vue.prototype.notifyWarning = function (msg) {
  this.$notify({
    title: '警告',
    message: msg,
    type: 'warning',
    customClass: 'customClassNotify',
  })
}

Vue.prototype.notifyInfo = function (msg) {
  this.$notify.info({
    title: '消息',
    message: msg,
    customClass: 'customClassNotify',
  })
}

Vue.prototype.notifyError = function (msg) {
  this.$notify.error({
    title: '错误',
    message: msg,
    customClass: 'customClassNotify',
  })
}

Vue.prototype.globalLoading = function (text) {
  const loading = this.$loading({
    lock: true,
    text: text,
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  return loading
}

Vue.prototype.globalMessageBox = function (
  title,
  tips,
  confirmButtonText,
  cancelButtonText,
  type
) {
  const messageBox = this.$confirm(title, tips, {
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    type: type,
  })
  return messageBox
}
Vue.component('uploadExcelCom', uploadExcelCom)

Vue.component('pictureLayer', pictureLayer)
Vue.directive('throttle', throttling)
Vue.directive('changeVal', changeVal)

// console.log(store.getters)
Vue.config.productionTip = false

Vue.use(ElementUI)

// 初始化 webSocket
const token = getToken()
if (token) {
  console.log("已登录", token)
  store.dispatch('CONNECT_SERVER').then(res => {
    console.log('CONNECT_SERVER', res)
  })
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
