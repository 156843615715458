// import home from "./home"
// import examManage from "./examManage"

import user from './user'
import mechanism from './mechanism'
import standard from "./standard"
import systemManagement from './systemManagement'
import account from './account'
import courseStart from './courseStart'
import materialManage from "./materialManage"
import safeInspect from "./safeInspect"
import taskManage from "./taskManage"



const allRouter = [
  user,
  mechanism,
  standard,
  taskManage,
  account,
  courseStart,
  materialManage,
  safeInspect,
  systemManagement,
]

export default allRouter