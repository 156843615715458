// 用户模块
import LayoutMain from '@/layout/LayoutMain'

const user = {
  path: '/user',
  component: LayoutMain,
  redirect: '/user/userManage',
  meta: {
    title: '用户管理',
    LoginRoad: '用户数据管理',
    icon: 'el-icon-user-solid',
  },
  children: [
    {
      path: '/user/tenantManage',
      name: 'tenantManage',
      meta: {
        title: '租户管理',
      },
      component: () => import('@/views/user/tenantManage.vue'),
    },
    {
      path: '/user/userManage',
      name: 'userManage',
      meta: {
        title: '账号管理',
        LoginRoad: '账号管理',
      },
      component: () => import('@/views/user/userManage/userManage.vue'),
    },
    {
      path: '/user/roleManage',
      name: 'roleManage',
      meta: {
        title: '角色管理',
        LoginRoad: '角色权限',
      },
      component: () => import('@/views/user/roleManage/roleManage.vue'),
    },
    {
      path: '/user/studentManage',
      name: 'studentManage',
      meta: {
        title: '学生管理',
        LoginRoad: '学生管理',
        hideNav: true,
      },
      component: () => import('@/views/user/studentManage/studentManage.vue'),
    },
    {
      path: '/user/studentInfo',
      name: 'studentInfo',
      meta: {
        title: '查看学生',
        hideNav: true,
      },
      component: () => import('@/views/user/studentManage/studentDetails.vue'),
    },
    {
      path: '/user/roleUrlManage',
      name: 'roleUrl',
      meta: {
        title: '路由管理',
      },
      component: () => import('@/views/user/roleManage/roleUrlManage.vue'),
    },
    {
      path: '/user/roleApiManage',
      name: 'roleApi',
      meta: {
        title: '接口管理',
      },
      component: () => import('@/views/user/roleManage/roleApiManage.vue'),
    },
  ],
}

export default user
