// 任务管理模块
import LayoutMain from "@/layout/LayoutMain";

const taskManage = {
  path: "/taskManage",
  component: LayoutMain,
  redirect: "/taskManage/expPlan",
  meta: {
    title: "任务管理",
    loginRoad: "任务管理",
    icon: "el-icon-s-claim",
  },
  children: [
    {
      path: "/taskManage/expPlan",
      name: "expPlan",
      meta: {
        title: "实验教学计划",
        LoginRoad: "实验教学计划",
      },
      component: () => import("@/views/taskManage/expPlan/expPlan.vue"),
    },
    {
      path: "/taskManage/checkPlan",
      name: "checkPlan",
      meta: {
        title: "实验仪器耗材盘点",
        LoginRoad: "实验仪器耗材盘点",
      },
      component: () => import("@/views/taskManage/checkPlan/checkPlan.vue"),
    },
    
    {
      path: "/taskManage/checkPlan/checkPlanDetail",
      name: "checkPlanDetail",
      meta: {
        title: "任务完成情况",
        LoginRoad: "任务完成情况",
        hideNav: true,
      },
      component: () => import("@/views/taskManage/checkPlan/checkPlanDetail.vue"),
    },
    {
      path: "/taskManage/purchasePlan",
      name: "purchasePlan",
      meta: {
        title: "采购需求收集",
        LoginRoad: "采购需求收集",
      },
      component: () => import("@/views/taskManage/purchasePlan/purchasePlan.vue"),
    },
    {
      path: "/taskManage/purchasePlan/purchasePlanDetail",
      name: "purchasePlanDetail",
      meta: {
        title: "任务完成情况",
        LoginRoad: "任务完成情况",
        hideNav: true,
      },
      component: () => import("@/views/taskManage/purchasePlan/purchasePlanDetail.vue"),
    },
    {
      path: "/taskManage/safeInspectPlan",
      name: "safeInspectPlan",
      meta: {
        title: "实验室安全巡查",
        LoginRoad: "实验室安全巡查",
      },
      component: () => import("@/views/taskManage/safeInspectPlan/safeInspectPlan.vue"),
    },
  ],
};

export default taskManage;
