
import { getDicFormInfoApi, getGradeDicInfoApi, getSessionDicInfoApi, getTermDicListApi } from '@/api/standard/dictionaryForm.js'
import { getAllSchoolWithoutPageApi } from "@/api/mechanism/examSchool";
const public_data = {
  state: {
    // 学校
    sysSchoolDicList: [],
    // 学年
    sysAcademicYearDicList: [],
    // 科目
    sysSubjectDicList: [],
    // 学段
    sysSemesterTermDicList: [],
    // 学段+年级
    sysSemesterTermGradeDicList: [],
    // 学年+学期
    sysAcademicTermYearDicList: [],
    dataArr: [
      { key: 6, val: 'sysAcademicYearDicList' },
      { key: 4, val: 'sysSubjectDicList' },
      { key: 5, val: 'sysSemesterTermDicList' }
    ],
    // 节次
    sysSessionDataDicList: []
  },
  mutations: {
    updateData (state, { val, data }) {
      state[val] = data.map(({ sysDicId: value, sysDicName: label, children }) => ({
        value,
        label,
        ...((val === 'sysSemesterTermGradeDicList' || val === 'sysAcademicTermYearDicList') && { children }),
      }));
    },
    updateSessionDicInfo (state, { data }) {
      state.sysSessionDataDicList = data.map(({ sysSchoolClassSessionId: value, sysSchoolClassSessionName: label }) => ({
        value,
        label,
      }));
    },
    updateSchool (state, { data }) {
      state.sysSchoolDicList = data.map(({ sysOrgSchoolId: value, name: label }) => ({
        value,
        label,
      }));
    }

  },
  actions: {
    // 字典表信息
    async loadData ({ commit }, data) {
      const { key, val } = data
      const res = await getDicFormInfoApi(key);
      if (res.success) {
        commit('updateData', { val, data: res.data });
        let arr = JSON.parse(JSON.stringify(res.data))
        //学段时 获取年级
        if (val === 'sysSemesterTermDicList') {
          for (const e of arr) {
            e.children = (await getGradeDicInfoApiFunc(e.sysDicId)).map(({ sysSchoolClassGradeId: value, sysSchoolClassGradeName: label }) => ({ value, label }));
          }
          commit('updateData', { val: 'sysSemesterTermGradeDicList', data: arr });
          //学年时 获取学期
        } else if (val === 'sysAcademicYearDicList') {
          for (const e of arr) {
            e.children = (await getSysSchoolTermYearDicIdListApiFunc({ sysSchoolTermYearDicId: e.sysDicId })).map(({ sysSchoolTermId: value, sysSchoolTermName: label }) => ({ value, label }));
          }
          commit('updateData', { val: 'sysAcademicTermYearDicList', data: arr });
        }
      } else {
        this.$message.warning(res.msg);
      }
    },
    // 获取节次
    async sessionDic ({ commit }) {
      const res = await getSessionDicInfoApi(1, 10000, {});
      if (res.success) {
        commit('updateSessionDicInfo', { data: res.data });
      } else {
        this.$message.warning(res.msg);
      }
    },
    async getAllSchoolList ({ commit }) {
      const res = await getAllSchoolWithoutPageApiFunc()
      if (res.success) {
        commit('updateSchool', { data: res.data });
      } else {
        this.$message.warning(res.msg);
      }
    }
  },
}
// 获取年级数据
const getGradeDicInfoApiFunc = async (val) => {
  try {
    const res = await getGradeDicInfoApi(val);
    if (res.success) {
      return res.data;
    } else {
      this.$message.warning(res.msg);
      return [];
    }
  } catch (error) { }
};
// 获取学期数据
const getSysSchoolTermYearDicIdListApiFunc = async (val) => {
  try {
    const res = await getTermDicListApi(1, 10000, val);
    if (res.success) {
      return res.data;
    } else {
      this.$message.warning(res.msg);
      return [];
    }
  } catch (error) { }
};
// 获取学期数据
const getAllSchoolWithoutPageApiFunc = async () => {
  try {
    const res = await getAllSchoolWithoutPageApi();
    if (res.success) {
      return res;
    } else {
      this.$message.warning(res.msg);
      return [];
    }
  } catch (error) { }
};
export default public_data