// 【auth】用户注册、认证、授权

import { request, noTimeOutReq } from '@/utils/request.js'
import store from '@/store'

// 获取版本号
export function getVersion () {
    return request({
        url: '/server/web/version/release/get',
        method: 'post'
    })
}

// 注册
export function register (data) {
    return request({
        url: '/auth/user/register/add',
        method: 'post',
        data
    })
}
// 登录
export function login (data) {
    return request({
        url: '/auth/user/login/get',
        method: 'post',
        data
    })
}

// 退出
export function quit () {
    store.commit('EMPTY_NAV')
    return request({
        url: '/auth/user/logout/delete',
        method: 'post',
    })
}

//获取服务器配置
export function getServerConfig () {
    return request({
        url: '/server/web/config/platform/local/list',
        method: 'post',
    })
}


// 获取用户所有角色 路由
export function getRole () {
    return request({
        url: '/user/auth/user/role/get',
        method: 'post',
    })
}


// 查询登录用户所属学校机构
export function getOrgSchoolList (userId) {
    return request({
        url: `/user/org/${userId}/list/school`,
        method: 'post',
    })
}

// 查询登录用户用户获取网页登录用的小程序码属学校机构
export function getWebLogin () {
    return request({
        url: `/mini/user/web/login/qrcode/get`,
        method: 'post',
    })
}

// 用户获取小程序码登陆状态
export function getLoginStatus (data) {
    return request({
        url: `/auth/user/unlimited/qrcode/login/status/get`,
        method: 'post',
        data
    })
}