// 用户模块
import LayoutMain from '@/layout/LayoutMain'

const courseStart = {
  path: '/courseStart',
  component: LayoutMain,
  redirect: '/courseStart/courseStartManage',
  meta: {
    title: '开课管理',
    LoginRoad: '开课管理',
    icon: 'el-icon-news',
  },
  children: [
    {
      path: '/courseStart/courseConfiguration',
      name: 'courseConfiguration',
      meta: {
        title: '课程配置',
      },
      component: () => import('@/views/courseStart/courseConfiguration.vue'),
    },
    {
      path: '/courseStart/lessonPlansManage',
      name: 'lessonPlansManage',
      meta: {
        title: '实验教学计划管理',
      },
      component: () => import('@/views/courseStart/lessonPlansManage.vue'),
    },
    {
      path: '/courseStart/lessonPlansManageCompletion',
      name: 'lessonPlansManageCompletion',
      meta: {
        title: '实验教学计划管理-完成情况',
        hideNav: true
      },
      component: () => import('@/views/courseStart/lessonPlansManageCompletion.vue'),
    },
    {
      path: '/courseStart/scheduleLessonsManage',
      name: 'scheduleLessonsManage',
      meta: {
        title: '排课管理',
      },
      component: () => import('@/views/courseStart/scheduleLessonsManage.vue'),
    },
    {
      path: '/courseStart/courseArrangement',
      name: 'courseArrangement',
      meta: {
        title: '课程安排',
        hideNav: true
      },
      component: () => import('@/views/courseStart/courseArrangement/courseArrangement.vue'),
    },
    {
      path: '/courseStart/experimentCurriculum',
      name: 'experimentCurriculum',
      meta: {
        title: '实验课课程表',
      },
      component: () => import('@/views/courseStart/experimentCurriculum.vue'),
    },
    {
      path: '/courseStart/startClass',
      name: 'startClass',
      meta: {
        title: '开课台账',
      },
      component: () => import('@/views/courseStart/startClass.vue'),
    },
    {
      path: '/courseStart/startClassStatistics',
      name: 'startClassStatistics',
      meta: {
        title: '开课情况统计',
      },
      component: () => import('@/views/courseStart/startClassStatistics.vue'),
    },
    {
      path: '/courseStart/startClassPlanView',
      name: 'startClassView',
      meta: {
        title: '开课台账-学校教学计划查看',
        hideNav: true
      },
      component: () => import('@/views/courseStart/startClassPlanView.vue'),
    },
    {
      path: '/courseStart/startClassView',
      name: 'startClassView',
      meta: {
        title: '开课台账-审批实验课程',
        hideNav: true
      },
      component: () => import('@/views/courseStart/startClassView.vue'),
    },
    {
      path: '/courseStart/curriculum',
      name: 'curriculum',
      meta: {
        title: '课程表',
        hideNav: true
      },
      component: () => import('@/views/courseStart/courseArrangement/curriculum.vue'),
    },
    {
      path: '/courseStart/courseRanking',
      name: 'courseRanking',
      meta: {
        title: '开课情况统计-课程排名',
        hideNav: true
      },
      component: () => import('@/views/courseStart/startClassStatistics/courseRanking.vue'),
    },
    {
      path: '/courseStart/courseVoucher',
      name: 'courseVoucher',
      meta: {
        title: '开课情况统计-课程凭证',
        hideNav: true
      },
      component: () => import('@/views/courseStart/startClassStatistics/courseVoucher.vue'),
    },
  ],
}

export default courseStart 
