import { getStore } from '@/utils/token.js'




export function imageAddress (url) {
  let imgUrl = getStore('LOCAL_OSS');
  if (window.server.SERVER_OSS_CONF_type) {
    imgUrl = window.server.SERVER_OSS
  }
  // console.log('返回的路径', imgUrl + url);
  // alert(imgUrl)
  return `${imgUrl}${url}`
}

export function apiAddress (url) {
  let apiUrl = window.server.SERVER_API;
  console.log('返回的路径', apiUrl + url);
  return `${apiUrl}${url}`
}

export function mediaAddress (url) {
  let mediaUrl = getStore('LOCAL_MEDIA');
  console.log('返回的路径', mediaUrl + url);
  return `${mediaUrl}${url}`
}

export function mediaHttpAddress (url) {
  let mediaHttpUrl = getStore('LOCAL_MEDIA_HTTP');
  if (window.server.SERVER_MEDIA_HTTP_CONF_TYPE) {
    mediaHttpUrl = window.server.SERVER_MEDIA_HTTP
  }
  // console.log('返回的路径', mediaUrl + url);
  return `${mediaHttpUrl}${url}`
}

export function wsAddress (url) {
  let wsUrl = getStore('LOCAL_WS');
  if (window.server.SERVER_WS_CONF_type) {
    wsUrl = window.server.SERVER_WS_API
  }
  // console.log('返回的路径', mediaUrl + url);
  return `${wsUrl}${url}`
}

// 倒计时
export function countdown (start, end) {
  var res = end - start;//剩余时间总的毫秒数
  res = res / 1000;//毫秒换算成秒
  var d = parseInt(res / 60 / 60 / 24);//天
  d = d < 10 ? '0' + d : d;
  var h = parseInt(res / 60 / 60 % 24);//时
  h = h < 10 ? '0' + h : h;
  var m = parseInt(res / 60 % 60);//分
  m = m < 10 ? '0' + m : m;
  var s = parseInt(res % 60);//秒
  s = s < 10 ? '0' + s : s;
  return h + ':' + m + ':' + s;
}
