<template>
  <el-dialog
    v-loading="loading"
    element-loading-text="正在上传中"
    title="导入"
    :visible.sync="dialogBox"
    width="400px"
    :before-close="handleClose"
  >
    <el-upload
      element-loading-text="上传中"
      class="upload-demo1"
      drag
      ref="upload"
      :show-file-list="false"
      action="https://jsonplaceholder.typicode.com/posts/"
      :http-request="uploadFunc"
      accept=".xls, .xlsx"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">
        将文件拖到此处，或
        <em>点击上传</em>
      </div>
      <div class="el-upload__tip" slot="tip">
        请选择文件上传，只能上传Excel文件
      </div>
    </el-upload>
    <div style="color: red; margin-top: 20px">{{ uploadMsg }}</div>
    <!-- <div slot="footer" class="dialog-footer">
      <el-button v-throttle  size="medium" @click="dialogBox = false">取 消</el-button>
      <el-button v-throttle  size="medium" type="primary" @click="uploadFunc">确 定</el-button>
    </div>-->
  </el-dialog>
</template>
<script>
export default {
  name: 'uploadExcelCom',
  data() {
    return {
      dialogBox: false,
    }
  },
  props: {
    fileName: {
      type: String,
      default: 'file',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    uploadMsg: {
      default: '',
    },
  },
  // mounted () { }
  methods: {
    uploadFunc(item) {
      // console.log('上传')
      let fd = new FormData()
      fd.append(this.fileName, item.file)
      this.$emit('uploadFunc', fd)
    },
    handleClose() {
      // console.log('关闭')
      this.clearFile()
      this.dialogBox = false
    },
    clearFile() {
      this.$refs.upload.clearFiles()
    },
  },
}
</script>

<style lang="scss" scoped>
.upload-demo1 .el-upload .el-upload--text {
  margin: 0 auto;
}
</style>
