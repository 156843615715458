<template>
  <div>
    <component
      @click="clickMenu(val)"
      class="menu-item"
      v-for="val in navList"
      :key="val.title + val.path"
      :index="val.path"
      :is="
        (val.children && val.children.length) > 0
          ? 'el-submenu'
          : 'el-menu-item'
      "
    >
      <!-- :index="val.path" -->
      <template #title>
        <!-- <i
          :class="
            (val.children && val.children.length) > 0
              ? [val.icon]
              : ['el-icon-caret-right']
          "
        ></i>-->
        <template v-if="loginCity">
          <i :class="val.icon ? [val.icon] : ['']"></i>
          <span>{{ val.LoginRoad }}</span>
        </template>
        <template v-else>
          <i :class="val.icon ? [val.icon] : ['el-icon-caret-right']"></i>
          <span>{{ val.title }}</span>
        </template>
      </template>
      <template v-if="val.children && val.children.length > 0">
        <nav-menu :navList="val.children"></nav-menu>
      </template>
    </component>
  </div>
</template>
<script>
import { getLoginRoad } from "@/utils/token.js";
export default {
  name: "NavMenu",
  data () {
    return {
      loginCity: false
    }
  },
  props: {
    navList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    //点击把菜单的名字传出去
    clickMenu (val) {
      if (this.loginCity) {
        (val.LoginRoad) && (val.title = val.LoginRoad)
      }
      this.$store.commit("pushTags", val)
    }
  },
  created () {
    this.loginCity = !!getLoginRoad()
  }
};
</script>
<style lang="scss" scoped>
::v-deep .el-submenu__title i,
.el-menu-item i {
  color: #fff;
}
</style>
