import { request, noTimeOutReq } from "@/utils/request.js";

// 列表【字典表信息】
export function getDicFormInfoApi(code) {
  return request({
    url: `/sys/dic/list`,
    method: "post",
    params: {
      code,
    },
  });
}

// 新增【字典数据】
export function addDicInfoApi(data) {
  return request({
    url: `/sys/dic/add`,
    method: "post",
    data,
  });
}

// 删除【字典数据】
export function deleteDicInfoApi(data) {
  return request({
    url: `/sys/dic/delete`,
    method: "post",
    data,
  });
}

// 获取【字典数据】详情
export function getDicInfoDetailApi(sysDicId) {
  return request({
    url: `/sys/dic/get`,
    method: "post",
    params: {
      sysDicId,
    },
  });
}

// 分页展示所有【字典表信息】
export function getDicFormDataListApi(pageIndex, pageSize, data) {
  return request({
    url: `/sys/dic/page/${pageIndex}/${pageSize}/list`,
    method: "post",
    data,
  });
}

// 修改【字典数据】
export function updateDicInfoApi(data) {
  return request({
    url: `/sys/dic/update`,
    method: "post",
    data,
  });
}

// 获取【字典类型】
export function getDicTypeApi(name) {
  return request({
    url: `/sys/dic/type/list`,
    method: "post",
    params: {
      name,
    },
  });
}

// 以下是学期字典表接口

// 新增【学期字典】
export function addTermDicApi(data) {
  return request({
    url: `/sys/school/term/add`,
    method: "post",
    data,
  });
}

// 删除【学期字典】
export function delTermDicApi(data) {
  return request({
    url: `/sys/school/term/delete`,
    method: "post",
    data,
  });
}

// 获取【学期字典】详情
export function getTermDicDetailApi(sysSchoolTermId) {
  return request({
    url: `/sys/school/term/get`,
    method: "post",
    params:{
      sysSchoolTermId
    },
  });
}

// 分页展示所有【学期字典】
export function getTermDicListApi(pageIndex, pageSize, data) {
  return request({
    url: `/sys/school/term/page/${pageIndex}/${pageSize}/list`,
    method: "post",
    data
  });
}

// 修改【学期字典】
export function updateTermDicApi(data) {
  return request({
    url: `/sys/school/term/update`,
    method: "post",
    data
  });
}

// 以下是年级字典表接口

// 新增【年级字典数据】
export function addGradeDicApi(data) {
  return request({
    url: `/sys/school/class/grade/add`,
    method: "post",
    data,
  });
}

// 删除【年级字典数据】
export function delGradeDicApi(data) {
  return request({
    url: `/sys/school/class/grade/delete`,
    method: "post",
    data,
  });
}

// 获取【年级字典数据】详情
export function getGradeDicDetailApi(sysSchoolClassGradeId) {
  return request({
    url: `/sys/school/class/grade/get`,
    method: "post",
    params: {
      sysSchoolClassGradeId,
    },
  });
}

// 列表展示【学年学期年级班级字典表信息】
export function getTermSessionGradeClassDicInfoApi(data) {
  return request({
    url: `/sys/school/class/grade/list/class`,
    method: "post",
    data,
  });
}

// 分页展示所有【年级字典表信息】
export function getGradeDicListApi(pageIndex, pageSize, data) {
  return request({
    url: `/sys/school/class/grade/page/${pageIndex}/${pageSize}/list`,
    method: "post",
    data,
  });
}

// 修改【年级字典数据】
export function updateGradeDicApi(data) {
  return request({
    url: `/sys/school/class/grade/update`,
    method: "post",
    data,
  });
}

// 列表展示【年级字典表信息】
export function getGradeDicInfoApi(sysDicId) {
  return request({
    url: `/sys/school/class/grade/list`,
    method: "post",
    params: {
      sysDicId,
    },
  });
}

// 以下是节次字典表接口

// 分页展示所有【课程节次字典】
export function getSessionDicInfoApi(pageIndex, pageSize, data) {
  return request({
    url: `/sys/school/class/session/page/${pageIndex}/${pageSize}/list`,
    method: "post",
    data,
  });
}

// 新增【课程节次字典】
export function addSessionDicApi(data) {
  return request({
    url: `/sys/school/class/session/add`,
    method: "post",
    data,
  });
}

// 删除【课程节次字典】
export function delSessionDicApi(data) {
  return request({
    url: `/sys/school/class/session/delete`,
    method: "post",
    data,
  });
}

// 获取【课程节次字典】详情
export function getSessionDicDetailApi(sysSchoolClassSessionId) {
  return request({
    url: `/sys/school/class/session/get`,
    method: "post",
    params: {
      sysSchoolClassSessionId,
    },
  });
}


// 修改【课程节次字典】
export function updateSessionDicApi(data) {
  return request({
    url: `/sys/school/class/session/update`,
    method: "post",
    data
  });
}