//TODO 什么都有的工具类

// 非空校验
export function isEmpty (obj) {
  return typeof obj === 'undefined' || obj === null || obj === '' || obj.length === 0
}

//身份证校验
export function validateSfz (rule, value, callback) {
  if (!value) {
    return callback(new Error('身份证号码不能为空'))
  }
  function validataCredentials (num) {
    var msg, boo //  msg身份证验证友情提示,boo返回值
    var num = num.toUpperCase() // 身份证为末尾可能是X
    //   身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，
    //   最后一位是校验位，可能为数字或字符X。
    const arrInt = new Array(
      7,
      9,
      10,
      5,
      8,
      4,
      2,
      1,
      6,
      3,
      7,
      9,
      10,
      5,
      8,
      4,
      2
    )
    const arrCh = new Array(
      '1',
      '0',
      'X',
      '9',
      '8',
      '7',
      '6',
      '5',
      '4',
      '3',
      '2'
    )
    var nTemp = 0,
      i
    if (!/(^\d{15}$)|(^\d{17}([0-9]|X)$)/.test(num)) {
      //  输入的身份证号长度不对，或者号码不符合规定;
      return [
        (boo = false),
        (msg = '输入的身份证号长度不对，或者号码不符合规定'),
      ]
    }
    //   校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
    //   下面分别分析出生日期和校验位
    var len, re
    len = num.length
    if (len == 15) {
      re = new RegExp(/^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/)
      var arrSplit = num.match(re)
      //   检查生日日期是否正确
      var dtmBirth = new Date(
        '19' + arrSplit[2] + '/' + arrSplit[3] + '/' + arrSplit[4]
      )
      var bGoodDay
      bGoodDay =
        dtmBirth.getYear() == Number(arrSplit[2]) &&
        dtmBirth.getMonth() + 1 == Number(arrSplit[3]) &&
        dtmBirth.getDate() == Number(arrSplit[4])
      if (!bGoodDay) {
        //   输入的身份证号里出生日期不对！
        return [(boo = false), (msg = '输入的身份证号里出生日期不对！')]
      } else {
        //   将15位身份证转成18位
        //   校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
        num = num.substr(0, 6) + '19' + num.substr(6, num.length - 6)
        for (i = 0; i < 17; i++) {
          nTemp += num.substr(i, 1) * arrInt[i]
        }
        num += arrCh[nTemp % 11]
        return [(boo = true), (msg = num)]
      }
    }
    if (len == 18) {
      re = new RegExp(/^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/)
      var arrSplit = num.match(re)
      //  检查生日日期是否正确
      var dtmBirth = new Date(
        arrSplit[2] + '/' + arrSplit[3] + '/' + arrSplit[4]
      )
      var bGoodDay
      bGoodDay =
        dtmBirth.getFullYear() == Number(arrSplit[2]) &&
        dtmBirth.getMonth() + 1 == Number(arrSplit[3]) &&
        dtmBirth.getDate() == Number(arrSplit[4])
      if (!bGoodDay) {
        //  输入的身份证号里出生日期不对！

        return [(boo = false), (msg = ' 输入的身份证号里出生日期不对')]
      } else {
        //  检验18位身份证的校验码是否正确。
        //  校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
        var valnum
        for (i = 0; i < 17; i++) {
          nTemp += num.substr(i, 1) * arrInt[i]
        }
        valnum = arrCh[nTemp % 11]
        if (valnum != num.substr(17, 1)) {
          //  18位身份证的校验码不正确！
          return [(boo = false), (msg = '身份证的校验码不正确！')]
        }
        return [(boo = true), (msg = '验证成功')]
      }
    }
    return [(boo = false), (msg = '身份证的长度不正确！')]
  }
  setTimeout(() => {
    var res = validataCredentials(value)
    if (!res[0]) {
      callback(new Error(res[1]))
    } else {
      callback()
    }
  }, 200)
}

//日期格式转化
export function formatDate (date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + ''
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      )
    }
  }
  return fmt
}

function padLeftZero (str) {
  return ('00' + str).substr(str.length)
}

export function validForbid (value, number = 255) {
  value = value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, '').replace(/\s/g, "");
  if (value.length >= number) {
    this.$message({
      type: "warning",
      message: `输入内容不能超过${number}个字符`
    });
  }
  return value;
}

export function verifyInput (val, limit = 0, max = 100, min = null) {
  val = val.replace(/[^\d.]/g, ""); //保留数字
  val = val.replace(/^00/, "0."); //开头不能有两个0
  val = val.replace(/^\./g, "0."); //开头为小数点转换为0.
  val = val.replace(/\.{2,}/g, "."); //两个以上的小数点转换成一个
  val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", "."); //只保留一个小数点
  /^0\d+/.test(val) ? val = val.slice(1) : ''; //两位以上数字开头不能为0
  const str = '^(\\d+)\\.(\\d{' + limit + '}).*$'
  const reg = new RegExp(str)
  if (limit === 0) {
    // 不需要小数点
    val = val.replace(reg, '$1')
  } else {
    // 通过正则保留小数点后指定的位数
    val = val.replace(reg, '$1.$2')
  }
  if (val > max) {
    val = max;
  }
  if (min !== null && val < min) {
    val = min
  }
  return val
}

// 防抖
export function debounce (fn, delay) {
  var delay = delay || 200;
  var timer;
  return function () {
    var th = this;
    var args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      timer = null;
      fn.apply(th, args);
    }, delay);
  };
}

//节流
export function throttle (fn, time, msg) {
  let timer = null
  time = time || 1000
  return function (...args) {
    if (timer) {
      // if (isEmpty(msg)) {
      //   this.msgWarning(`操作频繁,请在${time / 1000}s后操作`);
      // } else {
      //   this.msgWarning(msg);
      // }
      return;
    }
    const _this = this
    timer = setTimeout(() => {
      timer = null
    }, time)
    fn.apply(_this, args)
  }
}

// 秒转时间字符串，入参，秒
export function durationToTimeSting (duration) {
  duration = Math.floor(duration)
  const hh = Math.floor(duration / 3600)
  const mm = Math.floor((duration - (hh * 3600)) / 60)
  const ss = Math.floor(duration - (hh * 3600) - (mm * 60))
  let hhString = String(hh)
  if (hh < 10) {
    hhString = '0' + hhString
  }

  if (hh >= 99) {
    hhString = '99'
  }
  let mmString = String(mm)
  if (mm < 10) {
    mmString = '0' + mmString
  }
  if (mm >= 99) {
    mmString = '99'
  }
  let ssString = String(ss)
  if (ss < 10) {
    ssString = '0' + ssString
  }
  if (ss >= 99) {
    ssString = '99'
  }
  return hhString + ':' + mmString + ':' + ssString
}
