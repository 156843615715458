// 实验仪器耗材管理模块
import LayoutMain from "@/layout/LayoutMain";

const materialManage = {
  path: "/materialManage",
  component: LayoutMain,
  redirect: "/materialManage/drugEquipment",
  meta: {
    title: "仪器耗材管理",
    loginRoad: "仪器耗材管理",
    icon: "el-icon-s-order",
  },
  children: [
    {
      path: "/materialManage/drugEquipment",
      name: "drugEquipment",
      meta: {
        title: "仪器药品配备管理",
        LoginRoad: "仪器药品配备管理",
      },
      component: () =>
        import("@/views/materialManage/drugEquipment/drugEquipment.vue"),
    },
    {
      path: "/materialManage/materialAccount",
      name: "materialAccount",
      meta: {
        title: "仪器耗材总账",
        LoginRoad: "仪器耗材总账",
      },
      component: () =>
        import("@/views/materialManage/materialAccount/materialAccount.vue"),
    },
    {
      path: "/materialManage/inStockManage",
      name: "inStockManage",
      meta: {
        title: "入库管理",
        LoginRoad: "入库管理",
      },
      component: () => import("@/views/materialManage/inStockManage/inStockManage.vue"),
    },
    {
      path: "/materialManage/inStockAccount",
      name: "inStockAccount",
      meta: {
        title: "入库台账",
        LoginRoad: "入库台账",
      },
      component: () => import("@/views/materialManage/inStockManage/inStockAccount.vue"),
    },
    {
      path: "/materialManage/outStockManage",
      name: "outStockManage",
      meta: {
        title: "出库管理",
        LoginRoad: "出库管理",
      },
      component: () => import("@/views/materialManage/outStockManage/outStockManage.vue"),
    },
    {
      path: "/materialManage/outStockAccount",
      name: "outStockAccount",
      meta: {
        title: "出库台账",
        LoginRoad: "出库台账",
      },
      component: () => import("@/views/materialManage/outStockManage/outStockAccount.vue"),
    },
    // {
    //   path: "/materialManage/outInLib",
    //   name: "outInLib",
    //   meta: {
    //     title: "出入库管理",
    //     LoginRoad: "出入库管理",
    //   },
    //   component: () => import("@/views/materialManage/outInLib/outInLib.vue"),
    // },
    {
      path: "/materialManage/lossReport",
      name: "lossReport",
      meta: {
        title: "报损报失登记",
        LoginRoad: "报损报失登记",
      },
      component: () =>
        import("@/views/materialManage/lossReport/lossReport.vue"),
    },
    {
      path: "/materialManage/lossAccount",
      name: "lossAccount",
      meta: {
        title: "报损报失台账",
        LoginRoad: "报损报失台账",
      },
      component: () =>
        import("@/views/materialManage/lossReport/lossAccount.vue"),
    },
    {
      path: "/materialManage/purchase",
      name: "purchase",
      meta: {
        title: "采购清单管理",
        LoginRoad: "采购清单管理",
      },
      component: () => import("@/views/materialManage/purchase/purchase.vue"),
    },
    {
      path: "/materialManage/purchaseDetail",
      name: "purchaseDetail",
      meta: {
        title: "采购清单详情",
        LoginRoad: "采购清单详情",
        hideNav: true
      },
      component: () => import("@/views/materialManage/purchase/purchaseDetail.vue"),
    },
    {
      path: "/materialManage/checkRecord",
      name: "checkRecord",
      meta: {
        title: "盘点任务管理",
        LoginRoad: "盘点任务管理",
      },
      component: () =>
        import("@/views/materialManage/checkRecord/checkRecord.vue"),
    },
    {
      path: "/materialManage/checkRecordDetail",
      name: "checkRecordDetail",
      meta: {
        title: "盘点任务详情",
        LoginRoad: "盘点任务详情",
        hideNav: true
      },
      component: () =>
        import("@/views/materialManage/checkRecord/checkRecordDetail.vue"),
    },
    {
      path: "/materialManage/checkRecordAccount",
      name: "checkRecordAccount",
      meta: {
        title: "盘点台账",
        LoginRoad: "盘点台账",
      },
      component: () =>
        import("@/views/materialManage/checkRecord/checkRecordAccount.vue"),
    },
    {
      path: "/materialManage/dangerousMaterial",
      name: "dangerousMaterial",
      meta: {
        title: "危化品管理",
        LoginRoad: "危化品管理",
      },
      component: () =>
        import(
          "@/views/materialManage/dangerousMaterial/dangerousMaterial.vue"
        ),
    },
  ],
};

export default materialManage;
