// 实验教学标准管理模块
import LayoutMain from "@/layout/LayoutMain";

const standard = {
  path: "/standard",
  component: LayoutMain,
  redirect: "/standard/standardDeviceLib",
  meta: {
    title: "实验教学标准管理",
    loginRoad: "实验教学标准管理",
    icon: "el-icon-s-management",
  },
  children: [
    {
      path: "/standard/standardDeviceLib",
      name: "standardDeviceLib",
      meta: {
        title: "设备仪器标准库",
        LoginRoad: "设备仪器标准库",
      },
      component: () =>
        import("@/views/standard/standardDeviceLib/standardDeviceLib.vue"),
    },
    {
        path: "/standard/equipManagement",
        name: "equipManagement",
        meta: {
          title: "仪器耗材配备标准",
          LoginRoad: "仪器耗材配备标准",
        },
        component: () =>
          import("@/views/standard/equipManagement/equipManagement.vue"),
      },
      {
        path: "/standard/menuManagement",
        name: "menuManagement",
        meta: {
          title: "实验目录管理",
          LoginRoad: "实验目录管理",
        },
        component: () =>
          import("@/views/standard/menuManagement/menuManagement.vue"),
      },
      {
        path: "/standard/standardManagement",
        name: "standardManagement",
        meta: {
          title: "实验教学标准管理",
          LoginRoad: "实验教学标准管理",
        },
        component: () =>
          import("@/views/standard/standardManagement/standardManagement.vue"),
      },
      {
        path: "/standard/tagManagement",
        name: "tagManagement",
        meta: {
          title: "物品标签管理",
          LoginRoad: "物品标签管理",
        },
        component: () =>
          import("@/views/standard/tagManagement/tagManagement.vue"),
      },
      {
        path: "/standard/dicFormManagement",
        name: "dicFormManagement",
        meta: {
          title: "字典表管理",
          LoginRoad: "字典表管理",
        },
        component: () =>
          import("@/views/standard/dicFormManagement/dicFormManagement.vue"),
      }
  ],
};

export default standard