// 安全巡查管理模块
import LayoutMain from "@/layout/LayoutMain";

const safeInspect = {
  path: "/safeInspect",
  component: LayoutMain,
  redirect: "/safeInspect/inspectContent",
  meta: {
    title: "安全巡查管理",
    loginRoad: "安全巡查管理",
    icon: "el-icon-s-claim",
  },
  children: [
    {
      path: "/safeInspect/inspectContent",
      name: "inspectContent",
      meta: {
        title: "巡查内容管理",
        LoginRoad: "巡查内容管理",
      },
      component: () =>
        import("@/views/safeInspect/inspectContent/inspectContent.vue"),
    },
    {
      path: "/safeInspect/safetyInspection",
      name: "safetyInspection",
      meta: {
        title: "安全巡查管理",
        LoginRoad: "安全巡查管理",
      },
      component: () =>
        import("@/views/safeInspect/safetyInspection/safetyInspection.vue"),
    },
    {
      path: "/safeInspect/inspectDetail",
      name: "inspectDetail",
      meta: {
        title: "安全巡查详情",
        LoginRoad: "安全巡查详情",
        hideNav: true
      },
      component: () =>
        import("@/views/safeInspect/safetyInspection/inspectDetail.vue"),
    },
    {
      path: "/safeInspect/inspectTask",
      name: "inspectTask",
      meta: {
        title: "巡查任务管理",
        LoginRoad: "巡查任务管理",
      },
      component: () =>
        import("@/views/safeInspect/inspectTask/inspectTask.vue"),
    },
  ],
};

export default safeInspect;
