<template>
  <!-- class添加了一个样式 -->
  <div class="tagsBox" @contextmenu.prevent>
    <div
      @contextmenu.prevent="openMenu(item, $event,index)"
      :class="isActive(item.path) ? 'active' : ''"
      class="tagsView"
      v-for="(item, index) in tags"
      :key="index"
      @click="tagsMenu(item)"
    >
      {{ item.title }}
      <!-- 这个地方一定要click加个stop阻止，不然会因为事件冒泡一直触发父元素的点击事件，无法跳转另一个路由 -->
      <span class="el-icon-close tagsIcon" @click.stop="handleClose(item, index)" v-if="index"></span>
      <ul v-show="!!(visible == index)" class="contextmenu" v-if="index">
        <li @click.stop="handleClose(item, index)">关闭</li>
        <li @click.stop="clearOtherTags(item, index)">关闭其他</li>
        <li @click.stop="clearTags($route.path)">关闭所有</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data () {
    return {
      //右键菜单隐藏对应布尔值
      visible: -1,
    };
  },
  computed: {
    ...mapState({
      tags: state => state.tagsView.tags,
    }),
  },
  watch: {
    //监听右键菜单的值是否为true，如果是就创建全局监听点击事件，触发closeMenu事件隐藏菜单，如果是false就删除监听
    visible (value) {
      if (value) {
        document.body.addEventListener("click", this.closeMenu);
      } else {
        document.body.removeEventListener("click", this.closeMenu);
      }
    },
  },
  methods: {
    ...mapMutations(["pushTags", "closeTab", "clearTagsView", "clearOtherTagsView"]),
    // 清除其他
    clearOtherTags (item, index) {
      this.clearOtherTagsView(item)
      this.closeMenu()
    },
    //点击叉叉删除的事件
    handleClose (item, index) {
      console.log(item, index);
      //先把长度保存下来后面用来比较做判断条件
      let length = this.tags.length - 1;
      this.closeTab(item);
      if (item.path !== this.$route.path) return;
      console.log(length, index)
      if (index === length) {
        this.$router.replace({ path: this.tags[index - 1].path });
      } else {
        this.$router.replace({ path: this.tags[index].path });
      }
      this.closeMenu()
    },
    //点击跳转路由
    tagsMenu (item) {
      //判断：当前路由不等于当前选中项的url，也就代表你点击的不是现在选中的标签，是另一个标签就跳转过去，如果你点击的是现在已经选中的标签就不用跳转了，因为你已经在这个路由了还跳什么呢。
      if (this.$route.path != item.path) {
        //用path的跳转方法把当前项的url当作地址跳转。
        this.$router.replace({ path: item.path });
      }
    },
    //通过判断路由一致返回布尔值添加class，添加高亮效果
    isActive (route) {
      return route == this.$route.path;
    },
    //右键事件，显示右键菜单，并固定好位置。
    openMenu (tag, e, i) {
      this.visible = i;
      console.log('this.visible ', this.visible)
      // e.currentTarget.getElementsByClassName('contextmenu')[0].style.display = 'block'
      this.selectedTag = tag;
    },
    //隐藏右键菜单
    closeMenu () {
      this.visible = -1;
    },
    clearTags (val) {
      this.clearTagsView(val);
      this.closeMenu()
    },
  },
  mounted () {
    if (this.$route.path != '/homepage') {
      let li = { path: this.$route.path, title: this.$route.meta.title }
      !this.$route.meta.hideNav ? this.pushTags(li) : '';
      console.log('li', li)
    }
  }
};
</script>

<style lang="scss" scoped>
.tagsBox {
  width: 100%;
  background: #fff;
  height: 34px;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  padding-left: 20px;
  border-bottom: 1px solid #d8dce5;
  -webkit-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 12%), 0 0 3px 0 rgb(0 0 0 / 4%);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 12%), 0 0 3px 0 rgb(0 0 0 / 4%);
}
//标签导航样式
.tagsView {
  cursor: pointer;
  margin-right: 10px;
  margin-top: 2px;
  height: 26px;
  line-height: 26px;
  padding: 0 8px;
  border: 1px solid #d8dce5;
  // border-radius: 5px;
  color: #000;
  font-size: 12px;
  display: inline-block;
}
//叉号鼠标经过样式
.tagsIcon:hover {
  color: #f56c6c;
}
//标签高亮
.active {
  // background-color: #40ba84;
  background-color: #409eff;
  color: #fff;
  &::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #fff;
    margin-right: 6px;
    border-radius: 50%;
    line-height: 26px;
  }
}
//右键菜单样式
.contextmenu {
  // display: none;
  margin: 0;
  background: #fff;
  z-index: 100;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
  li {
    margin: 0;
    padding: 7px 16px;
    cursor: pointer;
    &:hover {
      background: #eee;
    }
  }
}
</style>
