import store from "@/store";

const webSocket = {
    state: {
        // 全局 websocket
        globalWebSocket: {},
        // 消息待发送
        socketMsgSenderQueue: [],
        // 消息待接收
        socketMsgReceive: {},
    },
    mutations: {
        CHANGE_COLLAPSE (state) {
            state.isCollapse = !state.isCollapse;
        },
        SEND_MSG(state ,data) {
            // sendMsg(data)
        },
        CONNECT_SERVER(state, data) {
            state.globalWebSocket = data
        },
        RECEIVE_MSG(state, data) {
            state.socketMsgReceive = data
        },
    },
    actions: {
        CONNECT_SERVER(context) {
            let wsUrl = getStore('LOCAL_WS');
            if (window.server.SERVER_WS_CONF_type) {
                wsUrl = window.server.SERVER_WS_API
            }

            let ws = new WebSocket(wsUrl + "/home");
            //当WebSocket创建成功时，触发onopen事件
            ws.onopen = function(){
                while (socketMsgQueue.length > 0) {
                    var msg = socketMsgQueue.shift();
                    ws.send(msg)
                }
                if (heartInterval) {
                    clearInterval(heartInterval);
                }
                ws.send(msgType.heartMsg)
                heartInterval = setInterval(() => {
                    // 检测连接状态
                    setTimeout(() => {
                        if (ws.readyState === 2 || ws.readyState === 3) {
                            // 重连
                            store.dispatch('CONNECT_SERVER').then(res => {
                                console.log('CONNECT_SERVER', res)
                            })
                        }
                    }, 1000)
                    ws.send(msgType.heartMsg)
                }, heartIntervalTime)
            }

            //当客户端收到服务端发来的消息时，触发onmessage事件
            ws.onmessage = function(e){
                //e.data 的数据格式也是字符串，手动解析这些数据才能得到其他格式的数据。
                // const _data = JSON.parse(e.data);
                let data = e.data;
                handlerMsg(data)
            }

            //当客户端收到服务端发送的关闭连接请求时，触发onclose事件
            ws.onclose = function(e){
                console.log(e);
                console.log("close");
            }

            //如果出现连接、处理、接收、发送数据失败的时候触发onerror事件
            ws.onerror = function(e){
                console.log(e);
                console.log("error");
            }

            context.commit('CONNECT_SERVER', ws);
        },
        SEND_MSG(context, data) {

            if (context.state.globalWebSocket.readyState === 1) {
                context.state.globalWebSocket.send(data)
            } else {
                context.state.socketMsgSenderQueue.push(data)
                setTimeout(() => {
                    if (context.state.globalWebSocket.readyState === 2 || context.state.globalWebSocket.readyState === 3) {
                        // 重连
                        store.dispatch('CONNECT_SERVER').then(() => {
                            console.log('CONNECT_SERVER', res)
                        })
                    }
                }, 1000)
            }
        },
        COLES_SERVER(context, data) {
            if (context.state.globalWebSocket.readyState === 1) {
                // 发送关闭消息提示
                context.state.globalWebSocket.send(data)
                setTimeout(() => {
                    context.state.globalWebSocket.close()
                }, 500)
            }
        },
    },
}

import {getStore, getToken} from "@/utils/token";
import {msgType} from "@/utils/webSocket";

let socketMsgQueue = [];
let heartInterval;
let heartIntervalTime = 20000;

function geneMsg()  {
    return {
        magicNum: 'rocpow',
        versionNum: '2.1',
        topic: 'default',
        from: '1',
        to: '1',
    }
}

function handlerMsg(data) {
    var msgDataType = data.substring(0,6)
    switch (msgDataType) {
        case msgType.heartMsg: {
            // 心跳包
            console.log("HEART:", msgDataType)
            break
        }
        case msgType.heartInitMsg: {
            // 心跳包
            if (heartInterval) {
                clearInterval(heartInterval);
            }
            store.dispatch('SEND_MSG', msgType.heartMsg).then(res => {
                console.log("SEND_MSG", res)
            })
            heartInterval = setInterval(() => {
                // 检测连接状态
                setTimeout(() => {
                    if (store.state.webSocket.globalWebSocket.readyState === 2 || store.state.webSocket.globalWebSocket.readyState === 3) {
                        // 重连
                        store.dispatch('CONNECT_SERVER').then(res => {
                            console.log('CONNECT_SERVER', res)
                        })
                    }
                }, 1000)
                store.dispatch('SEND_MSG', msgType.heartMsg).then(res => {
                    console.log("SEND_MSG", res)
                })
            }, heartIntervalTime);
            break
        }
        case msgType.authMsg: {
            var msgDataString = data.substring(6, data.length)
            var msgData = JSON.parse(msgDataString)
            // 认证消息
            if (msgData.success === 1) {
                return
            }
            let token = getToken()
            if (!!token) {
                // 发送验证消息
                var msg = geneMsg();
                msg.terminalType = 11
                msg.token = token
                store.dispatch('SEND_MSG', msgType.authMsg + JSON.stringify(msg)).then(res => {
                    console.log("SEND_MSG", res)
                })
            }
            break
        }
        default: {
            var msgDataString = data.substring(6, data.length)
            if (msgDataString && msgDataString > 0) {
                store.commit('RECEIVE_MSG', JSON.parse(msgDataString))
            }
        }
    }
}

export default webSocket